import React from "react"
import Layout from "../Layout"
import { Content } from "../components/rings/Content"
import { useStaticQuery, graphql } from "gatsby"

export default function Terms() {
  const termsData = useStaticQuery(graphql`
    query terms {
      sanityTerms(_id: { eq: "f175b203-c62b-44b1-bd8b-0c65ccbfce04" }) {
        _id
        titel
        _rawLongMessage
        isShown
      }
    }
  `)
  return (
    <Layout>
      {termsData && termsData.sanityTerms && (
        <Content content={termsData.sanityTerms} />
      )}
    </Layout>
  )
}
