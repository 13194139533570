import React from "react"
import styled from "styled-components"
import { colors, fontFamily, size, mq } from "../../global.style"
import { Container } from "../core/Container"
import { WysiWyg } from "../core/WysiWyg"

export const Content = ({ content }) => {
  return (
    <ContentStyled>
      <Container>
        <Title>{content.titel}</Title>
        <Message>
          <WysiWyg blocks={content._rawLongMessage} />
        </Message>
      </Container>
    </ContentStyled>
  )
}

const ContentStyled = styled.div`
  padding: 1.5rem 0;

  ${mq.minWidth(size.laptop)} {
    padding: 5rem 0;
  }
`

const Title = styled.h2`
  color: ${colors.text};
  ${fontFamily("OpenSansExtraBold")};
  line-height: 1.6;
  margin: 0;
  margin-bottom: 0.75rem;

  ${mq.minWidth(size.laptop)} {
    margin-bottom: 3rem;
    font-size: 2.75rem;
  }
`

const Message = styled.div`
  h3 {
    ${fontFamily("OpenSansExtraBold")};
    font-size: 1.25rem;
    margin: 0 0 1rem 0;

    ${mq.minWidth(size.portrait)} {
      font-size: 1.25rem;
      margin: 0 0 1rem 0;
    }

    ${mq.minWidth("1260px")} {
      margin: 2rem 0 1.5rem;
      font-size: 2rem;
    }
  }

  h4 {
    ${fontFamily("OpenSansExtraBold")};
    font-size: 0.85rem;
    margin: 0 0 1rem 0;

    ${mq.minWidth(size.portrait)} {
      font-size: 1rem;
      margin: 0 0 1rem 0;
    }

    ${mq.minWidth("1260px")} {
      margin: 0 0 1rem 0;
      font-size: 1.2rem;
    }
  }

  blockquote {
    ${fontFamily("OpenSansItalic")};
    margin: 1rem 0;
    padding-left: 1rem;
    font-size: 0.9rem;
    line-height: 1.6;

    strong {
      ${fontFamily("OpenSansBoldItalic")};
    }

    ${mq.minWidth(size.portrait)} {
      margin: 2.5rem 0;
      padding-left: 2rem;
    }

    ${mq.minWidth("1260px")} {
      margin: 5rem 0;
      line-height: 1.5;
      font-size: 1.5rem;
    }
  }

  li {
    ${mq.minWidth(size.phone)} {
      margin-bottom: 0.5rem;
    }

    ${mq.minWidth("1260px")} {
      margin-bottom: 0.5rem;
    }
  }

  ol,
  ul {
    margin: 0;
    margin-bottom: 2rem;
    padding: 0 0 0 1.25rem;

    ${mq.minWidth(size.phone)} {
      padding: 0 0 0 2.5rem;
    }
  }

  ol,
  ul,
  p {
    ${fontFamily("OpenSansSemiBold")};
    font-size: 0.9rem;
    line-height: 1.6;

    strong {
      ${fontFamily("OpenSansExtraBold")};
    }

    ${mq.minWidth("1260px")} {
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }
  }
`
